.root {
    width: 100%;
    height: 100%;
    border-radius: 26px;
    padding: 4.5%;
    overflow: auto;
    gap: 18px;
    touch-action: pan-y;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.root::-webkit-scrollbar {
    display: none;
}
  
.imageContainer {
    position: relative;
    mask-image: url('../../../../assets/square-mask.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    mask-position: center;
    width: 100%;
    
    aspect-ratio: 1.24;
    overflow: hidden;
    display: flex; /* or display: grid; */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-bottom: 16px;
    z-index: 2;
}

.containerWrapper {
    position: relative;
}

.imageContainerBackground {
    position: absolute;
    background-image: url('../../../../assets/square-background-color-2.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    
    width: 100%;
    aspect-ratio: 1.24;

    left: 0;
    right: 0;
    top: 2px;
    bottom: -2px;
}

.imageContainerBackgroundColor2 {
    background-image: url('../../../../assets/square-background-color-2.svg');
}

.imageContainerBackgroundColor3 {
    background-image: url('../../../../assets/square-background-color-3.svg');
}

.imageContainerBackgroundBlur {
    position: absolute;
    background-image: url('../../../../assets/square-background-blur.svg');
    background-repeat: no-repeat;
    background-size: 'cover';
    background-position: bottom;
    
    width: 110%;
    aspect-ratio: 1.24;

    left: -5%;
    right: 0px;
    top: 5%;
    bottom: -5%;
}

.image {
    width: 100%;
}

.textWrapper {
    position: relative;
}

.overflow:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    border-radius: 0 0 26px 26px;
}

.color-2.overflow:after {
    background: linear-gradient(to top, rgb(255, 222, 157, 1), rgb(255, 222, 157, 0.99) 55%, rgb(255, 222, 157, 0.8) 80%, rgba(255, 222, 157, 0));
}

.color-3.overflow:after {
    background: linear-gradient(to top, rgb(200, 187, 250, 1), rgb(200, 187, 250, 0.99) 55%, rgb(200, 187, 250, 0.8) 80%, rgba(255, 222, 157, 0));
}

.title {
    font-weight: var(--global-font-weight-bold);
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 8px;
}

.text {
    font-weight: var(--global-font-weight-regular);
    font-size: 20px;
}

.sliderContainer {
    position: absolute;
    bottom: 14px;
    left: 16px;
    z-index: 5;
}

.padding {
    padding-bottom: 50px;
}


.color-2 {
    background-color: var(--global-color-2);
}


.color-3 {
    background-color: var(--global-color-3);
}

