.root {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
}

.rotateRight {
    transform: rotate3d(.2,-.466,0,15deg) rotate(1.66deg);
    box-shadow:
    1em 2em 3em -2em rgba(0,0,0,.5),
    1em 2em 3.5em -2.5em rgba(0,0,0,.5);
}

.rotateLeft {
    transform: rotate3d(-.2,.466,-0,15deg) rotate(-1.66deg);
    box-shadow:
    2em 4em 6em -2em rgba(0,0,0,.5),
    1em 2em 3.5em -2.5em rgba(0,0,0,.5);
}

.rotateLeftSmall {
    transform: rotate3d(-.2,.466,-0,15deg) rotate(-0.26deg);
    box-shadow:
    2em 4em 6em -2em rgba(0,0,0,.5),
    1em 2em 3.5em -2.5em rgba(0,0,0,.5);
}