.root {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10% 4%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 20px;
}

.root::-webkit-scrollbar {
    display: none;
}


.picture {
    display: flex;
    justify-content: center;
    flex-shrink: 1;
}

.picture img {
    width: 80%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 1;
}

.name {
    font-size: 22px;
    font-weight: var(--global-font-weight-bold);
}

.email {
    font-size: 18px;
    font-weight: var(--global-font-weight-light);
}

.buttonsContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    width: 100%;
}

.back {
    position: absolute;
    left: 4%;
    top: 2%;
}