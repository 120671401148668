.root {
    background-color: var(--global-color-1);
    mask-image: url('../../assets/footermenu-background.svg');
    mask-repeat: no-repeat;
    mask-size: cover;

    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 16px;
}

.fixedBottom {
    position: absolute;
    bottom: 0;
}
