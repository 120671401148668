.root {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  padding-top: 50px;
  gap: 40px;
  justify-content: space-between;
}

.thumbnailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url('../../assets/stars.svg');
  background-size: contain;
}

.list {
  width: 90%;
}

.credits {
  font-size: 22px;
  font-weight: var(--global-font-weight-bold);
  line-height: 27px;
  color: var(--global-color-black);
  text-align: center;
}

.buttons {
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.content {
  padding: 0 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.payButtonContainer {
  padding-bottom: 10%;
}

.paragraph {
  text-align: center;
  font-size: 16px;
  font-weight: var(--global-font-weight-normal);
  line-height: 20px;
  color: var(--global-color-black);
}



  