.root {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100dvh;
  justify-content: space-between;
}


.page {
  box-shadow: 2px 2px 2px rgba(0,0,0,0.09);
}

.pagesContainer {
  touch-action: none;
  position: relative;
  padding: 0 3%;
  flex-grow: 1;
  margin-top: 6px;
  margin-bottom: 14px;
}

.pageContainer {
  touch-action: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}