.root {
    position: relative;
    width: 100%;
    border: 1px solid var(--global-color-2-dark);
    background-color: var(--global-color-2);
    border-radius: 20px;
    padding: 10px;
}

.root::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 0px var(--global-color-2-dark);
    z-index: -1;
    border-radius: 20px;
}

.textArea {
    width: 100%;
    min-height: 50px;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: var(--global-font-weight-regular);
    font-size: 14px;
    color: var(--global-color-black);
    resize: none;
}

.textArea::placeholder {
    color: rgb(69, 94, 58);
}
