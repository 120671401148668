.root {
    position: relative;
    width: 100%;
    align-items: center;
    background-color: var(--global-color-1);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 4%;
    padding: 6% 4%;
    border-radius: 20px;
}

.root:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 0px var(--global-color-1-dark);
    z-index: -1;
    border-radius: 20px;
}

.backgroundImageContainer {
    overflow: hidden;
    position: absolute;
    height: 70%;
    top: 0px;
    right: 0px;
    border-radius: 20px;
}

.backgroundImage {
    position: relative;
    width: 105%;
    height: 105%;
    right: -16px;
    top: -16px;
}

.moon {
    height: 105%;
}

.moon .backgroundImage {
    right: -10%;
    top: -34%;
}

.title {
    flex: 1;
    display: flex;
    font-weight: var(--global-font-weight-bold);
    line-height: 25px;
    font-size: 20px;
    overflow: hidden;
    color: var(--global-color-black);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.thumbnail {
    flex: 0 0 30%;
    overflow: hidden;
    aspect-ratio: 1.05;
    background-color: var(--global-color-1-dark);
    mask-image: url('../../assets/pumpkin-mask.svg');
    mask-repeat: no-repeat;
    mask-size: cover;
}

.controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    padding: 0;
}

.blob {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--global-color-1-dark);
    mask-image: url('../../assets/blob.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-size: cover;
}

.root-color-1 {
    background-color: var(--global-color-1);
}

.root-color-1::after {
    box-shadow: 0px 5px 0px var(--global-color-1-dark);
}

.color-1-dark {
    background-color: var(--global-color-1-dark);
}

.root-color-2 {
    background-color: var(--global-color-2);
}

.root-color-2::after {
    box-shadow: 0px 5px 0px var(--global-color-2-dark);
}

.color-2-dark {
    background-color: var(--global-color-2-dark);
}

.root-color-3 {
    background-color: var(--global-color-3);
}
.root-color-3::after {
    box-shadow: 0px 5px 0px var(--global-color-3-dark);
}

.color-3-dark {
    background-color: var(--global-color-3-dark);
}

.root-color-4 {
    background-color: var(--global-color-4);
}

.root-color-4::after {
    box-shadow: 0px 5px 0px var(--global-color-4-dark);
}

.color-4-dark {
    background-color: var(--global-color-4-dark);
}
