.root {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
}


.list {
    padding-top: 80px;
    padding-bottom: 100px;
}

.list::-webkit-scrollbar {
    display: none;
  }

.emptyStateRoot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
    height: 100%;
    padding: 0 6%;
}

.createButton {
    margin-top: 15px;
}

.title {
    text-align: center;
    font-weight: var(--global-font-weight-bold);
    font-size: 22px;
}

.text {
    text-align: center;
    font-weight: var(--global-font-weight-normal);
}