:root {
  --global-color-1: #ACDEFF;
  --global-color-1-dark: #83C0E8;
  
  --global-color-2: #FFDE9D;
  --global-color-2-dark: #FBB731;

  --global-color-3: #C8BBFA;
  --global-color-3-dark: #957FE8;

  --global-color-4: #F6A9DC;
  --global-color-4-dark: #F369C5;

  --global-color-black: #212121;
  --global-color-white: #FFFFFF;

  --global-font-family: "Red Rose", serif;
  --global-font-weight-light: 300;
  --global-font-weight-regular: 400;
  --global-font-weight-bold: 700;
}

.root {
  position: relative;
  font-family: var(--global-font-family);
  font-weight: var(--global-font-weight-regular);
  font-optical-sizing: auto;
  font-style: normal;

  width: clamp(200px, 100%, 450px);
  height: 100dvh;
  margin: none;
  overflow: hidden;



  /* display: flex;
  flex-direction: column;
  align-self: center; */
  
  background: linear-gradient(
                180deg, 
                rgba(255, 222, 157, 0.2) 0%, 
                rgba(255, 222, 157, 0.05) 20%, 
                rgba(255, 222, 157, 0.48) 41.41%, 
                rgba(255, 222, 157, 0) 100%), 
                url('../assets/background.svg');
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-position: center;
  background-size: contain;
  
  
  /* overflow: scroll;
  z-index: 1;
  flex-grow: 1;
  display: flex; */
}

* {
  box-sizing: border-box;
  font-family: var(--global-font-family);
  font-weight: var(--global-font-weight-regular);
  font-optical-sizing: auto;
  font-style: normal;
}
