.root {
    width: 100%;
    height: 100%;
   

    display: flex;
    flex-direction: column;

    padding: 20px;
    gap: 40px;
    overflow-x: scroll;
}

.root::-webkit-scrollbar {
    display: none;
}

.logoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: radial-gradient(
        ellipse at center,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 70%
    );
}

.logoContainer img {
    width: 80%
}