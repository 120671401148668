.root {
 
}

.input {
    position: relative;
    background-color: #F4E8E3;
    color: var(--global-color-black);
    border-radius: 30px;
    border: 1px solid #C8BBFA;
    box-shadow: 0px 4px 0px #957FE8;
    font-family: var(--global-font-family);
    font-weight: var(--global-font-weight-normal);
    height: 50px;
    margin-bottom: 15px;
}


.input::placeholder {
    color: var(--global-color-black);
}

.label {
    font-family: var(--global-font-family);
    font-weight: var(--global-font-weight-bold);
    font-size: 16px;
    line-height: 20px;
    color: var(--global-color-black);
    margin-bottom: 15px;
}

.button {
    font-family: var(--global-font-family);
    font-size: 16px;
    border: none;
    font-weight: var(--global-font-weight-bold);
    background-color: #ACDEFF;
    border-radius: 30px;
    color: var(--global-color-black);
    box-shadow: 0px 4px 0px #957FE8;
    text-transform: uppercase;
    line-height: 26px;
    margin-bottom: 30px;
    height: 45px;
}

.button:first-child {
    background-color: #FFCDEE;
    box-shadow: 0px 4px 0px 0px #F369C5;
}

.button:nth-child(2) {
    background-color: #C8BBFA;
    box-shadow: 0px 4px 0px 0px #957FE8;
}

.button:nth-child(3) {
    background-color: #FFDE9D;
    box-shadow: 0px 4px 0px 0px #FBB731;

}

.message {
    font-family: var(--global-font-family);
    font-size: 16px;
}

.anchor {
    font-family: var(--global-font-family);
    font-weight: var(--global-font-weight-normal);
    font-size: 14px;
    line-height: 20px;
    color: var(--global-color-black);
    text-decoration: none;

}

.divider {
    height: 2px;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.01) , rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.01));
}
