.root {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.text {
    position: relative;
    font-size: 14px;
    font-weight: var(--global-font-weight-nomral);
    color: var(--global-color-black);
    top: -1px
}