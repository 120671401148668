.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.mainContainer {
  height: 100%;
  padding: 26% 5% 5% 5%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  overflow-y: scroll;
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.thumbnailContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url('../../assets/stars.svg');
  background-size: contain;
}

.buttonSection {
  display: flex;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
}

.paymentButtons {
  width: 100%;
}
.content {
  padding: 0 20px;
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.package {
  font-size: 22px;
  font-weight: var(--global-font-weight-bold);
  line-height: 27px;
  color: var(--global-color-black);
  text-align: center;
}