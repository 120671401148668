.root {
  position: relative;
  --page-padding: 6%;
  display: flex;
  height: 100%;
  padding: 60px 0 0 0;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: space-between;
  gap: 4%;
  z-index: 1;
}

.root::-webkit-scrollbar {
  display: none;
}

.header {
  position: fixed;
  top: 16px;
  left: 10px;
  z-index: 1;
}

.sectionsContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.buttonContainer {
  padding: 8% var(--page-padding);
}


.mainTitle {
  font-size: 30px;
  line-height: 30px;
  color: var(--global-color-2-dark);
  font-weight: var(--global-font-weight-bold);
  margin: 0px var(--page-padding) 4% var(--page-padding);
  text-align: left;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
  font-weight: var(--global-font-weight-normal);
  margin: 0px var(--page-padding) 4% var(--page-padding);
  font-style: italic;
}

.carousel {
  padding-left: 20px;
  padding-right: var(--page-padding);
}

.textAreaContainer {
  padding: 0 var(--page-padding);
}

.buttonContainer {
  z-index: 2;
}