.color-1 rect {
    fill: var(--global-color-1-dark);
}

.color-2 rect {
    fill: var(--global-color-2-dark);
}


.color-3 rect {
    fill: var(--global-color-3-dark);
}


.color-4 rect{
    fill: var(--global-color-4-dark);
}