.root {
    font-size: 16px;
    font-weight: var(--global-font-weight-bold);
    margin: 0 auto;
    text-transform: uppercase;
    line-height: 20px;
    padding: 20px 90px;
    background-image: url('../../assets/button.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

}

.disabled {
    opacity: 0.4;
}