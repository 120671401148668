.root {
    position: relative;
    width: 100%;
    background-color: #fbe8f4;
    padding: 4% 4%;
    border-radius: 20px;
}

.root:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 5px 0px var(--global-color-1-dark);
    z-index: -1;
    border-radius: 20px;
}

.innerContainer {
    background-color: #F6A9DC;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 4%;
    display: flex;
    flex-direction: column;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}
.bottomContainer {
    flex-grow: 1;
    display: flex;
    padding-top: 8px;
    padding-bottom: 8px;
}


.title {
    flex: 1;
    display: flex;
    font-weight: var(--global-font-weight-bold);
    line-height: 25px;
    font-size: 20px;
    overflow: hidden;
    color: var(--global-color-black);

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
}
